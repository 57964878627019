<template>
  <div>
    <div class='doc' v-if="!isLoading" v-bind:class="{'frame':whereAre}">
      <div>
        <h2>Заявление о предоставлении дополнительных услуг<br> № заявки {{clientInfo.DealCode}}</h2>
      </div>
      <section class="section">
        <div class="boxes">
          <boxItem v-for="(item, index) in boxArr"
            :key="index"
            :item="item"
            :DataConfirm="DataConfirm"
          ></boxItem>

        </div>

        <p>
          В случае, если в таблице «Информация об услугах третьих лиц» мною проставлена отметка рядом с услугой, прошу перечислить часть займа в размере, составляющем стоимость дополнительной услуги, третьему лицу, предоставляющему соответствующую услугу.
          Я подтверждаю, что правила оказания услуг третьими лицами мне разъяснены и мною получены. Я уведомлен о том, что правила оказания третьими лицами услуг доступны в Личном кабинете заемщика.
          Я уведомлен о том, что приобретение услуг третьего лица не влияет на решение кредитора о предоставлении мне займа и на его условия.
          Я уведомлен о моем праве отказаться от услуги третьего лица в течение 30 календарных дней со дня выражения мною согласия на ее оказание посредством обращения к третьему лицу, оказывающему дополнительную услугу, с заявлением об отказе от такой услуги.
          Я также уведомлен, что вправе требовать от лица, оказывающего дополнительную услугу, возврата денежных средств, уплаченных за оказание такой услуги, за вычетом стоимости части такой услуги, фактически оказанной мне до дня получения лицом, оказывающим такую услугу, моего заявления об отказе от такой услуги. В случае, если третье лицо не исполняет обязанность по возврату мне денежных средств при отказе от дополнительной услуги, я вправе обратиться в ООО МКК «ФИНМОЛЛ» с заявлением о возврате денежных средств при отказе от дополнительной услуги.
        </p>

        </section>
    </div>
    <loader v-else></loader>
  </div>
</template>
<script>


export default {
  name: "agreementBoxes",
  props:{
    DataConfirm:{
      type:String,
      default:''
    },
    guid:{
      type:String,
      default:''
    },
  },
  data() {
    return {
      isLoading: true,
      boxArr:[],
      clientInfo:{}
    }
  },
  computed:{
    whereAre(){
      return this.guid?.length > 0;
    }
  },
  components: {
    loader: () => import('@/components/other/loader'),
    boxItem: () => import('@/views/agreement/agreementBoxesItem.vue')
  },
  mounted() {
      this.isLoading = true;
      let demGuid = this.guid?.length > 0 ? this.guid : this.$route.params.uid;

    this.$external.universalRequest({
      externalType: 'zita',
      action: 'getBoxArr',
      GUID: demGuid
    }).then((res)=>{
      for ( let i = 0; i<res.boxArr.length; i++) {
        res.boxArr[i].Print_Discription = res.boxArr[i].Print_Discription.split('{*дд.мм.гггг*}').join(res.clientInfo.RejectDate)
      }
      this.boxArr = res.boxArr;
      this.clientInfo = res.clientInfo;
      this.isLoading = false;
    }).catch((res) => {
      if ( res.errorCode > 0 || res.errorCode !== undefined ) {
        alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
      }
    })
  },
}
</script>

